import { Author } from './author';
import { Singer } from './singer';

export class Song {
    public songID : string;
    public songName : string;
    public description : string;
    public dateCreate : Date;
    public published : boolean;
    public plays : number;
    public trackURI : string;
    public genre : string;
    public thumbURL : string;
    public duration : number;
    public singersString : string;
    public singerImg : string;
    public authors: Author[];
    public singers: Singer[];

    public get singerConcat() {
        return this.singers.map(s => s.singerName)
        .join(' - ');
    }

    public size() : number{
        return this.singers.length;
    }
}