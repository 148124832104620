import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SongService } from './service/song.service';
import { Song } from './data/schema/song';
import { MatBasicAudioPlayerComponent, Track } from 'ngx-audio-player';
import { PlyrComponent } from 'ngx-plyr';

import * as Plyr from 'plyr';
import { timeout } from 'rxjs/operators';
import { Singer } from './data/schema/singer';
import { ThemePalette } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public space = ' ';

  public allSingers: Singer[] = [];
  title = 'singer-app';
  private DEFAULT_SINGER = 'SINGER1';
  public currentSingerId = "SINGER1";
  public currentSinger: Singer = new Singer();
  public songs : Song[]; 
  public singers : Singer[]; 
  public currentIndex = 0;
  public currentSong: Song = new Song();

  public loaded = false;
  public config: PerfectScrollbarConfigInterface = {};


  // get the component instance to have access to plyr instance
  @ViewChild(PlyrComponent, { static: true })
  plyr: PlyrComponent;

  // or get it from plyrInit event
  player: Plyr;

  audioSources = [
    {
      src: 'http://bansacphuongnam.com/data/audio/2017/07/dropbox/chieudonggiolanhve.mp3',
      type: 'audio/mp3',
    }
  ];
  public dd: ThemePalette;

  ngOnInit() {
    this.getAllSingers();
  }

  constructor(private songService: SongService, private titleService: Title, private router:Router,
    private route: ActivatedRoute) {
    this.route.queryParams
      .subscribe(params => {
        this.currentSingerId = params.artistId;
        this.switchSinger();
        this.getSongs();
      }
    );
    this.setTitle('');
   }

  getSongs(): void {
    this.loaded = false;
    this.songService.getSongs(this.getCurrentSingerId())
    .subscribe(songs => {
      this.songs = songs;
      this.currentIndex = 0;
      if (this.songs.length > 0) {
        this.singers = this.songs[this.currentIndex].singers;
        this.currentSong = this.songs[this.currentIndex];
        this.audioSources = [
          {
            src: this.songs[this.currentIndex].trackURI,
            type: 'audio/mp3',
          }
        ];
      }
      this.loaded = true;
    });
  }

  public buildSingers(song: Song): string {
    if (song && song.singers) {
      return song.singers.map(s => s.singerName).join(' - ');
    }
    return '';
  }
  public buildAuthors(): string {
    if (this.songs && this.songs.length > 0) {
      var currentSong = this.songs[this.currentIndex];
      if (currentSong && currentSong.authors) {
        return currentSong.authors.map(s => s.authorName).join(' - ');
      }
    }
    return '';
  }
  played(event: Plyr.PlyrEvent) {
    console.log('played', event);
  }

  play(): void {
    this.plyr.player.play(); // or this.plyr.player.play()
  }

  pause(): void {
    this.player.pause(); // or this.plyr.player.play()
  }

  stop(): void {
    this.player.stop(); // or this.plyr.player.stop()
  }

  public isPlaying(): boolean {
    return this.plyr && this.plyr.player && this.plyr.player.playing;
  }

  end(): void {
    this.currentIndex++;
    this.singers = this.songs[this.currentIndex].singers;
    this.currentSong = this.songs[this.currentIndex];
    this.playSong();
    this.setTitle(this.currentSong.songName + " - " + this.buildSingers(this.currentSong));
  }

  selectItem(index: number): void {
    if (this.currentIndex === index && this.isPlaying()) {
      return;
    }
    this.currentIndex = index;
    this.singers = this.songs[this.currentIndex].singers;
    this.currentSong = this.songs[this.currentIndex];
    this.setTitle(this.currentSong.songName + " - " + this.buildSingers(this.currentSong));
    setTimeout(() => {
      this.playSong();
    }, 1000);
    
  }

  playSong(): void {
    this.audioSources = [
      {
        src: this.songs[this.currentIndex].trackURI,
        type: 'audio/mp3',
      }
    ];
    this.plyr.player.restart();
    const playPromise : any = this.plyr.player.play();
    if (playPromise !== null){
        playPromise.catch(() => { this.plyr.player.play(); })
    }
  }

  public setTitle( newTitle: string) {
    let title = 'Giai điệu quê hương';
    if (newTitle) {
      title += ' - ' + newTitle;
    }
    this.titleService.setTitle( title );
  }

  public toggleSideBar() {
    document.getElementsByTagName('body')[0].classList.toggle("iconic-sidebar");
    
  }

  public openSidebar(e: any) {
    e.stopPropagation();
    document.getElementsByTagName('body')[0].classList.add('open-sidebar');
    document.querySelector('.sidebar-backdrop').classList.add('show');

  }
  public hideSidebar(e: any) {
    document.getElementsByTagName('body')[0].classList.remove("open-sidebar");
    if (document.querySelector('.sidebar-backdrop')) {
      document.querySelector('.sidebar-backdrop').classList.remove('show');
    }
  }


  getAllSingers(): void {
    this.songService.getSingers()
    .subscribe(singers => {
      this.allSingers = singers;
      if (this.getCurrentSingerId()) {
        this.currentSinger = this.allSingers.filter(s => s.singerID === this.getCurrentSingerId())[0];
      } else {
        this.currentSinger = this.allSingers[0];
      }
      this.switchSinger();
      this.getSongs();
    });
  }

  private switchSinger(): void {
    this.hideSidebar(null);
    this.currentSinger = this.allSingers.filter(s => s.singerID === this.getCurrentSingerId())[0];
    this.songs = [];
    this.getSongs();
    if (this.currentSinger) {
      this.setTitle('Tiếng hát ' + this.currentSinger.singerName)
    }
  }

  private getCurrentSingerId(): string {
    if (this.currentSingerId) {
      return this.currentSingerId;
    }
    return this.DEFAULT_SINGER;
  }

  public scroll(e :any): void {
    if (document.querySelector('#wrapper').scrollTop > 80) {
      document.querySelector('#header').classList.add("scrolled");
    } else {
      document.querySelector('#header').classList.remove("scrolled");
    }
    
  }

  public buildLinkDownload(): string {
    let url = 'https://docs.google.com/forms/d/e/1FAIpQLScXRGdPFI-k8pMZC7rPydef8gPjx4SpTgEXaWR5wAfGh6_Kfg/viewform?entry.1000020=Tuyển+tập+giọng+ca+'
    + this.currentSinger.singerName.split(' ').join('+');
    return 'https://m.me/bansacphuongnam';
  }
}
