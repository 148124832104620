<div id="wrapper" (scroll)="scroll($event)" class="ps" [perfectScrollbar]="config">
    <aside id="sidebar" class="sidebar-primary">
        <div class="sidebar-header d-flex align-items-center">
            <a href="#" class="brand">
                <img src="assets/img/logo.png" alt="listen-app">
            </a>
            <button type="button" class="btn p-0 ml-auto" id="hideSidebar" (click)="hideSidebar($event)">
                <i class="fa fa-arrow-left" aria-hidden="true"></i></button>
            <button type="button" class="btn toggle-menu" id="toggleSidebar" (click)="toggleSideBar()"><span></span> <span></span>
                <span></span></button>
        </div>
        <nav class="navbar">
            <ul class="navbar-nav">
                <li class="nav-item nav-header">Danh sách Nghệ sĩ</li>
            </ul>
            <ul class="navbar-nav ps" [perfectScrollbar]="config">
                
                <li class="nav-item" *ngFor="let item of allSingers; index as i;"
                [ngClass]="{'bg-light font-weight-bold': this.currentSingerId == item.singerID}">
                    <a [routerLink]="['/']" [queryParams]="{ artistId: item.singerID }" class="nav-link">
                        <img alt="{{item.singerName}}" class="card-img--radius-sm" title="{{item.singerName}}"
                        src="{{item.imageURI}}"/>
                        <span>{{item.singerName}}</span>
                    </a>
                </li>
                <!-- <li class="nav-item" *ngFor="let item of allSingers; index as i;"
                    (click)="switchSinger(item.singerID, i)"
                    [ngClass]="{'active font-weight-bold': item.singerID == currentSingerId}">
                    <div class="d-flex flex-row align-items-center">
                        <img width="100" src="{{item.imageURI}}"
                        alt="{{item.singerName}}" class="rounded-circle mr-3 singer-thumb">
                        <span>{{item.singerName}}</span>
                    </div>
                </li> -->
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <div class="nav-link text-muted">
                        <span style="padding-left: 0;">Liên hệ: bansacphuongnam@gmail.com</span>
                    </div>
                </li>
            </ul>
        </nav>
        <div class="sidebar-footer"></div>
    </aside>
    <main id="pageWrapper">
        <header id="header" class="">
            <div class="d-flex align-items-center">
                <button type="button" class="btn toggle-menu mr-3"
                    id="openSidebar" (click)="openSidebar($event)">
                    <span></span> <span></span> <span></span>
                </button>
                    <!-- <div class="d-flex flex-row align-items-center">
                        <img width="100" src="{{currentSinger.imageURI}}"
                        alt="{{currentSinger.singerName}}" class="rounded-circle mr-3 singer-thumb">
                        
                    </div> -->
                <form action="#" id="searchForm" *ngIf="currentSinger">
                    <div class="d-flex align-items-center">
                        <img id="singerAvatar" src="{{currentSinger.imageURI}}"
                            alt="{{currentSinger.singerName}}" class="rounded-circle mt-1 mr-3 singer-thumb">
                        <h6 class="mt-2">Nghệ sĩ <span class="text-uppercase">{{currentSinger.singerName}}</span></h6>
                    </div>
                </form>
                <!-- <ul class="header-options d-flex align-items-center">
                    <li><i class="fa fa-download" aria-hidden="true"></i>{{space}}Tải về</li>
                </ul> -->
            </div>
        </header>
        <div class="banner bg-song"></div>
        <div class="main-container" id="appRoute">

            <div class="heading">
                <div class="d-flex flex-wrap align-items-end">
                    <div class="flex-grow-1">
                        <h4 *ngIf="currentSinger">Tiếng hát {{currentSinger.singerName}}</h4>
                        <div class="d-flex flex-row">
                            <p *ngIf="songs">{{songs.length}} bài hát</p>
                            <a target="_blank" href="{{buildLinkDownload()}}"
                                class="btn btn-success ml-3 ml-auto">
                                <i class="fa fa-download" aria-hidden="true"></i>{{space}}Liên hệ mua và tải về
                            </a>
                        </div>
                    </div>
                </div>
                <hr>
            </div>
           <!-- <div class="row align-items-end"><span class="col-6 font-weight-bold">{{songs.length}} bài hát</span>
                <div class="col-6 ml-auto">
                    <form action="#" class="form-inline justify-content-end"><label class="mr-2" for="filter2">Sorted
                            By:</label> <select class="custom-select mr-sm-2" id="filter2">
                            <option selected="selected">Popular</option>
                            <option value="1">A-Z</option>
                            <option value="2">Z-A</option>
                        </select></form>
                </div>
                <div class="col-12">
                    <hr>
                </div>
            </div> -->
            <div class="section">
                <div class="d-flex justify-content-center mt-3">
                    <mat-spinner *ngIf="!loaded" [diameter]="40"></mat-spinner>
                </div>
                <div class="custom-list">
                    <div class="custom-list--item list-group-item-action" *ngFor="let item of songs; index as i;"
                        (click)="selectItem(i)" [ngClass]="{'active font-weight-bold': i == currentIndex}">
                        <div class="text-dark custom-card--inline">
                            <div class="custom-card--inline-img">
                                <img *ngIf="i != currentIndex || !isPlaying()" src="{{item.thumbURL}}" alt=""
                                    class="card-img--radius-sm">
                                <span class="numeric" *ngIf="i == currentIndex && isPlaying()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                        viewBox="0 0 24 24">
                                        <rect class="eq-bar eq-bar--1" x="4" y="4" width="3.7" height="8" />
                                        <rect class="eq-bar eq-bar--2" x="10.2" y="4" width="3.7" height="16" />
                                        <rect class="eq-bar eq-bar--3" x="16.3" y="4" width="3.7" height="11" />
                                    </svg>

                                </span>
                            </div>
                            <div class="custom-card--inline-desc">
                                <p class="text-truncate mb-0">{{item.songName}}
                                    <span class="badge badge-light ml-2 badge-pill">{{item.plays | shortNumber}} 
                                        <i class="fa fa-headphones" aria-hidden="true"></i></span>
                                </p>
                                <p class="text-truncate text-muted font-sm">{{buildSingers(item)}}</p>
                            </div>
                        </div>
                        <ul class="d-flex ml-auto">
                            <li class="badge badge-warning badge-pill">{{item.genre}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="audioPlayer" class="player-primary " [ngClass]="{'d-none': currentSong.songID == null}">
            <div class="audio" *ngIf="currentSong.songID && currentSong.songID != ''">
                <div class="song-image"><img data-amplitude-song-info="cover_art_url"
                        src="{{this.currentSong.thumbURL}}" alt=""></div>
                <div class="song-info pl-3">
                    <span class="song-name d-inline-block text-truncate" data-amplitude-song-info="name">
                        {{this.currentSong.songName}} <i class="badge badge-light">{{buildAuthors()}}</i></span>
                    <span class="song-artists d-block text-muted" data-amplitude-song-info="artist">{{buildSingers(this.currentSong)}}</span>
                </div>
                <div class="song-info-tooltip">
                    <ng-template #popTemplate>
                        <div>
                            <span data-amplitude-song-info="name" class="d-inline-block font-weight-bold"> {{this.currentSong.songName}}
                            <i class="badge badge-light">{{buildAuthors()}}</i></span>
                            <span data-amplitude-song-info="artist" class="d-block text-muted">
                                {{buildSingers(this.currentSong)}}</span>
                        </div>
                    </ng-template>
                    <button type="button" class="btn"
                            [popover]="popTemplate" popoverTitle="Thông tin bài hát">
                            <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            <div class="audio-controls justify-content-end pl-0 pr-3">
                <div class="d-flex flex-row justify-content-center w-100">
                    <div style="width: 100%;" class="" mat-card-image plyr plyrTitle="Audio" plyrType="audio"
                        [plyrPlaysInline]="true" [plyrCrossOrigin]="false" (plyrEnded)="end()"
                        [plyrSources]="audioSources"></div>
                </div>
            </div>
            <!-- <div class="audio-info d-flex align-items-center pr-4"><span class="mr-4"><span
                        class="amplitude-current-minutes">00</span>:<span class="amplitude-current-seconds">00</span> /
                    <span class="amplitude-duration-minutes">00</span>:<span
                        class="amplitude-duration-seconds">00</span></span>
                <div class="audio-volume dropdown"><button class="btn btn-icon-only" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false"><i class="ion-md-volume-low"></i></button>
                    <div class="dropdown-menu dropdown-menu-right volume-dropdown-menu"><input type="range"
                            class="amplitude-volume-slider" value="100"></div>
                </div>
                <div class="dropleft"><button class="btn btn-icon-only" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false"><i class="fa fa-ellipsis-v"></i></button>
                    <ul class="dropdown-menu">
                        <li class="dropdown-item"><a href="javascript:void(0);" class="dropdown-link"><i
                                    class="fa fa-heart-o"></i> <span>Favorite</span></a></li>
                        <li class="dropdown-item"><a href="javascript:void(0);" class="dropdown-link"><i
                                    class="la la-plus"></i> <span>Add to Playlist</span></a></li>
                        <li class="dropdown-item"><a href="javascript:void(0);" class="dropdown-link"><i
                                    class="la la-download"></i> <span>Download</span></a></li>
                        <li class="dropdown-item"><a href="javascript:void(0);" class="dropdown-link"><i
                                    class="la la-share-alt"></i> <span>Share</span></a></li>
                        <li class="dropdown-item"><a href="song-details.html" class="dropdown-link"><i
                                    class="la la-info-circle"></i> <span>Song Info</span></a></li>
                    </ul>
                </div><button class="btn btn-icon-only" id="playList"><i class="ion-md-musical-note"></i></button>
            </div> -->
        </div>
    </main>
</div>
<div class="backdrop sidebar-backdrop"></div>