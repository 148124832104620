import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Song } from '../data/schema/song';
import { Observable, of } from 'rxjs';

import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Singer } from '../data/schema/singer';

@Injectable({ providedIn: 'root' })
export class SongService {
    private getSongUrl = 'https://bansacphuongnam.com/rest/song/getSongBySinger?all=1&singerID=';
    private getSingerUrl = 'https://bansacphuongnam.com/rest/singer/getSingerPaging?numberItem=1000';
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(
        private http: HttpClient) { 

        }

    getSongs(singerId: string = "SINGER1"): Observable<Song[]> {
        return this.http.get<Song[]>(this.getSongUrl + singerId)
            .pipe(
            tap(_ => this.log('fetched songs')),
            catchError(this.handleError<Song[]>('getSongs', []))
            );
    }

    getSingers(): Observable<Singer[]> {
        return this.http.get<Singer[]>(this.getSingerUrl)
            .pipe(
                tap(_ => this.log('fetched songs')),
                catchError(this.handleError<Singer[]>('getSingers', []))
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
    
          console.error(error);
    
          this.log(`${operation} failed: ${error.message}`);
    
          return of(result as T);
        };
    }
    private log(message: string) {
        console.log(`SongService: ${message}`);
    }
}